import React, {useEffect} from 'react'
import { BrowserRouter } from 'react-router-dom'
import ScrollToTop from './services/ScrollToTop'
import Header from './component/common/Header'
import AppRouter from './Routes/AppRouter'
import './App.css'
import Footer_second from './component/common/Footer_second'
import { useState, createContext } from 'react'
import ConfettiEB from './component/common/loaders/ConfettiFinal'
import AnivStrip from './component/common/aniversaryStrip'
export const EventContext = createContext({});

const App = () => {
	const [pathname, setPathname] = useState("");
	const [eventData, setEventData] = useState("");
	const [haveEventData, setHaveEventData] = useState("");

	
	
	useEffect(()=>{
		
		setPathname(window.location.pathname);
	},[window.location.pathname]);

	if (eventData  > 0) {
		return null; 
	  } else {
		return (
		  <BrowserRouter>
			<EventContext.Provider value={{ eventData, setEventData,haveEventData,setHaveEventData}}>
			  <ScrollToTop />
			  {/* <ConfettiEB/> */}
			  <Header />
			  <AppRouter />
			  {/* <AnivStrip/> */}
			  <Footer_second pathname={pathname} />
			</EventContext.Provider>
		  </BrowserRouter>
		);
	  }
	};
  


export default App