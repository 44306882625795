import React, { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import backendurl from "../constants";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function OurProjects() {
  const [data, setData] = useState([]);
  const [item, setItem] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  const hardCodedArr = [
    {
      name: "Web Development",
      image: "2023-1_image.jpg"
    },
    {
      name: "eCommerce Development",
      image: "2023-1_eCommerce_development.jpeg"
    },
    {
      name: "Mobile App Development",
      image: "2023-1_Mobile_App_Development_2.png"
    },
    {
      name: "Creative Design Solution",
      image: "2022-11_Creative_Design_Solution.jpg"
    },
    {
      name: "Animation",
      image: "2022-11_Animation.jpg"
    },
    {
      name: "Digital Marketing Solution",
      image: "2023-1_Digital_marketing-1.jpg"
    }
  ]

  useEffect(() => {
    fetch(backendurl + "ourprojects-show")
      .then((response) => response.json())
      .then((data) => setData(data));

    fetch(backendurl + "ourprojectsitem-show-limit")
      .then((res) => res.json())
      .then((item) => setItem(item));
  }, []);

  const sliderSettings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>

      <section className="our-project-wr" scroll-color="white">
        <div className="center-wrapper">
          <div className="features-widget clearfix">

            <div className="our-project-heading left">
              <div className="common-heading">
                <h3>
                  {data.title ? <span>{data.title}</span> : <span>Our Projects</span>}
                  {data.name ? <span> {data.name} </span> : <span>Are the optimal solution</span>}
                </h3>
              </div>
            </div>

            {data.description ? (
              <div
                className="features-description-blk right wow"
                data-splitting
              >
                <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
              </div>
            ) : (
              <div
                className="features-description-blk right wow"

              >
                <p><p>Our <Link><span style={{ color: "#f84525" }}>team</span></Link> ensures customer satisfaction by delivering quality projects that provide the perfect solution for business growth. Our portfolio of diverse and successful projects is a core reason you can trust us with your company needs. We have worked with countless technologies and integrations in several industries over the past decade.</p></p>
              </div>
            )}
          </div>
          <div className="scroll-line project-widget-line"></div>
          {windowWidth <= 767 ? (<>
            <div className="new-industry-slider">
              <Slider {...sliderSettings}>
                {item?.map((post) => (
                  <div className="industries-box left" key={post._id}>
                    <figure>
                      <img src={"/assets/imgupload/" + post.image} alt="fitness-icon" />
                    </figure>
                    <div className="industries-box-heading">
                      <h4>
                        <Link>
                          <span data-hover={post.name}>{post.name}</span>
                        </Link>
                      </h4>
                    </div>
                  </div>
                ))}
              </Slider>

            </div>
          </>) : (<>
            <div className="project-widget-area">
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 320: 1, 650: 2, 1024: 3 }}
                gutter="60"
              >
                <Masonry
                  columnsCount={3}
                  gutter="60px"
                  className="project-masonry-grid"
                >

                  {item.map((post) => (
                    <div className="trigger-hover" key={post._id}>
                      <div className="dcard">
                        <div className="trigger"></div>
                        <div className="trigger"></div>
                        <div className="trigger"></div>
                        <div className="trigger"></div>
                        <div className="trigger"></div>
                        <div className="trigger"></div>
                        <div className="trigger"></div>
                        <div className="trigger"></div>
                        <div className="trigger"></div>
                        <div className="project-block wow fadeInDown">
                          <figure>
                            <div className="card-image">
                              <img
                                src={"/assets/imgupload/" + post.image}
                                alt="Project"
                              />
                            </div>
                            <figcaption>
                              <h3>
                                <Link to={post.description}> {post.name}</Link>
                              </h3>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                  ))

                  }

                </Masonry>
              </ResponsiveMasonry>
            </div>
          </>)}



        </div>
      </section>

    </>
  );
}

export default OurProjects;
