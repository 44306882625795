import React, { useEffect, useState, useContext, useRef } from "react";
// import { createPortal } from 'react-dom';
import Home from "./Home";
import DigitalLegacy from "./DigitalLegacy";
import ProjectSection from "./ProjectSection";
import ExpertiseSecond from "./Expertise_second";
import OurProjects from "./OurProjects";
import IndustriesSection from "./IndustriesSection";
import ClientDetailsSection from "./ClientDetailsSection";
import AwradsCertification from "./AwradsCertification";
import ChristmasLoader from "../common/loaders/christmasLoader";
import $ from 'jquery'
import SantaImage from "../common/santaAnimation";
import { EventContext } from "../../App";
import Loadingpage from "../common/Loadingpage";
import ConfettiE from "../common/loaders/ConfettiE";
import ConfettiFinal from "../common/loaders/ConfettiFinal";
import SantaAnimation from "../../component/common/santaAnimation";
import NewYear from "../common/loaders/NewYear";
import AnivStrip from "../common/aniversaryStrip";
import common_banner from "../common/CommonBanner";
import HoliLoader from "../common/HoliLoader";
import HoliStrip from "../common/HoliStrip";

const HomeLayout = () => {



  useEffect(() => {
    $("body").css("overflow", "hidden");
  }, []);

  return (
    <>

      <div className="conativeLoadingDiv" id="loadingdiv" style={{ background: `#000` }}>

        {/* <ConfettiE/> */}
        {/* <Loadingpage /> */}
       
        <Loadingpage />
        {/* <ConfettiFinal /> */}
        {/* <ChristmasLoader /> */}
        {/* <SantaAnimation/> */}
        {/* <NewYear/> */}
        {/* <common_banner/> */}
        {/* <AnivStrip /> */}
        {/* <HoliLoader />
        <HoliStrip /> */}


      </div>
      <div className="wrapper">
        <Home />
        <DigitalLegacy />
        <ProjectSection />
        <ExpertiseSecond />
        <IndustriesSection />
        <OurProjects />
        <ClientDetailsSection />
        <AwradsCertification />

      </div>
    </>
  );
};

export default HomeLayout;
