import React, { useState, useEffect } from "react";
 import "../../assets/css/HoliStrip.css";
 import { Link } from "react-router-dom";

function HoliStrip() {
  const [showOnScroll, setShowOnScroll] = useState(false);

  useEffect(() => {
    const handleFtrScroll = () => {
      const footer = document.querySelector(".footer-wr");
      if (footer) {
        const footerPosition = footer.getBoundingClientRect();
        if (footerPosition.top < window.innerHeight) {
          setShowOnScroll(true);
          return;
        }
      }
      setShowOnScroll(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleFtrScroll);
    return () => {
      window.removeEventListener("scroll", handleFtrScroll);
    };

  }, []);

  return (
    <div className={``}>
      <div className="marquee-wr">
        <div className="marquee-container">
          <h3>
            Wishing you a <span>Joyful and Colorful Holi!</span> May this festival bring
            <span className="bold"> Happiness and Prosperity </span> to you and your loved ones.
            <Link to="/life-at-conative" className="btn">
              <span className="aniv-btn-inner">Celebrate with Us</span>
            </Link>
          </h3>
        </div>
      </div>
    </div>
  );
}

export default HoliStrip;