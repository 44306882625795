import React, { Fragment, useEffect } from "react";
import {
  Routes,
  Route,
  Link,
  Navigate
} from "react-router-dom";
 
import HomeLayout from "../component/home/HomeLayout";
import ContactLayout from "../component/contact/ContactLayout";
import Career_details from "../component/career/Career_details";
import Footer_second from "../component/common/Footer_second";
import Lifeatconative from "../component/about/Lifeatconative";
import Aboutus from "../component/about/About_us";
import Ecommerce from "../component/industries/Ecommerce";
import Industriesserve from "../component/industries/Industries-serve";
import Portfolio from "../component/portfolio/Portfolio";
import Testimonial from "../component/testimonial/Testimonial";
import Team from "../component/team/team";
import Header from "../component/common/Header";
import PageNotFound from "../component/common/PageNotFound";
import IndustriesWeServe from "../component/IndustriesWeServe/IndustriesWeServe";
import websiteDevelopment from "../component/websiteDevelopment/websiteDevelopment";
import GraphicsDesign from "../component/GraphicsDesign/GraphicsDesign";
import WebDesignDeveploment from "../component/services/webdesignanddevelopment";
import Mobiledevelopment from "../component/services/mobileappdevelopment";
import EcommerceDevelopment from "../component/services/ecommerceandcms";
import Designing from "../component/services/designing";
import DigitalMarketing from "../component/services/digitalmarketingandcontent";
import Animation from "../component/services/animation";
import SiteMap from "../component/sitemap/sitemap";
import GraphicDesign from "../component/portfolio/GraphicDesign";

function AppRouter() {
 
  useEffect(()=>{
    window.scrollTo({top:0,behavior:"smooth"})
  })
  return (
    <>
        <Routes>
          <Route exact path={`/`} element={<HomeLayout />} />
          
          <Route path={`/contact-us`} element={<ContactLayout />} />
          <Route path={`/careers`} element={<Career_details/>} />
          <Route path={`/careers/:slug`} element={<Career_details/>} />
          <Route path={`/life-at-conative`} element={<Lifeatconative/>} />
          <Route path={`/testimonial`} element={<Testimonial/>} />
          <Route path={`/about-us`} element={<Aboutus/>} />
          <Route path={`/ecommerce`} element={<Ecommerce/>} />
          <Route path={`/industriesserve`} element={<Industriesserve/>} />
          <Route
            path={`/website-portfolio`}
            element={<Portfolio/>}
          />
          <Route
            path={`/website-portfolio/:slug`}
            element={<Portfolio/>}
          />
          <Route path={`/team`} element={<Team/>} />
          <Route path={`/industries-we-serve`} element={<IndustriesWeServe/>} />
          {/* <Route path={`/website-portfolio`} element={<websiteDevelopment/>} /> */}
          {/* <Route path={`/graphic-design-portfolio`} element={GraphicDesign} /> */}
          <Route exact path={`/graphic-portfolio`} element={<GraphicsDesign/>} />
          <Route exact path={`/graphic-portfolio/:slug`} element={<GraphicsDesign/>} />
          <Route
            path={`/website-design-development-services`}
            element={<WebDesignDeveploment/>}
          />
          <Route
            path={`/mobile-app-development-services`}
            element={<Mobiledevelopment/>}
          />
          <Route
            path={`/ecommerce-development-services`}
            element={<EcommerceDevelopment/>}
          />
          <Route path={`/animation-services`} element={<Animation/>} />
          <Route
            path={`/digital-marketing-services`}
            element={<DigitalMarketing/>}
          />
          <Route path={`/graphic-design-services`} element={<Designing/>} />
          <Route path={`/sitemap`} element={<SiteMap/>} />
          <Route path={`/404`} element={<PageNotFound/>} />
          <Route path={`/need-custom-wordpress-website`} element={<Navigate to="/website-design-development-services"></Navigate>}>
          </Route>
          <Route path={`/graphics-design/social-media-design`} element={<Navigate to="/digital-marketing-services"></Navigate>}>
            
          </Route>
          <Route path={`/enhance-look-business-graphic-design-services`} element={<Navigate to="/graphic-design-services"></Navigate>}>
            
          </Route>
          <Route path={`/basic-information-graphics-logo-design-services`} element={<Navigate to="/logo-design"></Navigate>}>
            
          </Route>
          <Route path={`/contact-us`} element={<Navigate to="/contact-us"></Navigate>}>
            
          </Route>
          <Route path={`/contact`} element={<Navigate to="/contact-us"></Navigate>}>
            
          </Route>
          <Route
            path={`/how-to-do-a-redirect-to-another-route-with-react-router`}
			element={<Navigate to="/website-design-development-services"></Navigate>}
          >
            
          </Route>
          <Route path={`/tag/magento-web-development`} element={ <Navigate to="/ecommerce-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/services/seo-and-smm/`} element={<Navigate to="/digital-marketing-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/software-development-company-in-indore`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/best-seo-company-indore-improve-business-enterprise/`} element={<Navigate to="/digital-marketing-services"></Navigate>}>
            
          </Route>
          <Route path={`/get-a-quote`} element={<Navigate to="/contact-us"></Navigate>}>
            
          </Route>
          <Route path={`/services/mobile-app-developmemt`} element={ <Navigate to="/mobile-app-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/professional-logo-graphic-design-services-indore/`} element={<Navigate to="/graphic-portfolio/logo-design"></Navigate>}>
            
          </Route>
          <Route path={`/career`} element={<Navigate to="/careers"></Navigate>}>
            
          </Route>
          {/* <Route path={`/careers/`} element={<Navigate to="/careers"></Navigate>}>
            
          </Route> */}
          <Route path={`/portfolio-type/business-card-design-cat/?cat_id=22`} element={<Navigate to="/graphic-portfolio/business-card-design"></Navigate>}>
            
          </Route>
          <Route path={`/design-development/`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/faq`} element={<Navigate to="/contact-us"></Navigate>}>
            
          </Route>
          <Route path={`/flexplumbing`} element={<Navigate to="/website-portfolio"></Navigate>}>
            
          </Route>
          <Route path={`/webdevelopment`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/corporate-identity`} element={<Navigate to="/graphic-portfolio/brand-identity-design"></Navigate>}>
            
          </Route>
          <Route path={`/LET'S%20TALK`} element={<Navigate to="/contact-us"></Navigate>}>
            
          </Route>
          <Route path={`/enhance-look-business-graphic-design-services/`} element={<Navigate to="/graphic-design-services"></Navigate>}>
            
          </Route>
          <Route path={`/portfolio-type/brand-identity-design-cat/`} element={<Navigate to="/graphic-portfolio/brand-identity-design"></Navigate>}>
            
          </Route>
          <Route
            path={`/incomparable-web-development-company-indore-conative-solution/`}
			element={<Navigate to="/website-design-development-services"></Navigate>}
          >
            
          </Route>
          <Route path={`/tag/seo/`} element={<Navigate to="/digital-marketing-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/website-designing-company-in-indore/`} element={<Navigate to="/graphic-portfolio/logo-design"></Navigate>}>
            
          </Route>
          <Route path={`/how-to-choose-the-software-development-company-cits/`} element={<Navigate to="/about-us"></Navigate>}>
            
          </Route>
          <Route path={`/tag/web-development-company-in-indore/`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route
            path={`/find-best-web-designing-company-indore-great-services/`}
			element={<Navigate to="/website-design-development-services"></Navigate>}
          >
            
          </Route>
          <Route path={`/join-software-company-career-growth/`} element={<Navigate to="/careers"></Navigate>}>
            
          </Route>
          <Route path={`/services/mobile-app-developmemt/`} element={<Navigate to="/mobile-app-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/website-development-company-indore/`} element={ <Navigate to="/website-design-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/get-a-quote/`} element={<Navigate to="/contact-us"></Navigate>}>
            
          </Route>
          <Route path={`/tag/software-comapny-indore/`} element={<Navigate to="/about-us"></Navigate>}>
            
          </Route>
          <Route path={`/category/magento/`} element={<Navigate to="/ecommerce-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/services/seo-and-smm/`} element={<Navigate to="/digital-marketing-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/software-company-in-indore/`} element={<Navigate to="/about-us"></Navigate>}>
            
          </Route>
          <Route path={`/portfolio-type/business-card-design-cat/`} element={ <Navigate to="/graphic-portfolio/business-card-design"></Navigate>}>
           
          </Route>
          <Route path={`/tag/joomla-development/`} element={<Navigate to="/ecommerce-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/it-company-indore/`} element={<Navigate to="/about-us"></Navigate>}>
            
          </Route>
          <Route
            path={`/software-company-indore-provide-various-different-services-cits/`}
			element={<Navigate to="/website-design-development-services"></Navigate>}
          >
            
          </Route>
          <Route path={`/portfolio-type/flyer-pamphlet-design-cat/`} element={ <Navigate to="/graphic-portfolio/flyer-pamphlet-design"></Navigate>}>
           
          </Route>
          <Route path={`/services/website-design/`} element={ <Navigate to="/website-design-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/points-hiring-graphic-designer-design-logo/`} element={<Navigate to="/graphic-portfolio/logo-design"></Navigate>}>
            
          </Route>
          <Route
            path={`/tag/website-design-and-development-company-in-indore/`}
			element={<Navigate to="/about-us"></Navigate>}
          >
            
          </Route>
          <Route path={`/tag/logo-design-services-indore/`} element={ <Navigate to="/graphic-design-services"></Navigate>}>
           
          </Route>
          <Route
            path={`/magento-web-development-best-e-commerce-website-platform/`}
			element={<Navigate to="/ecommerce-development-services"></Navigate>}
          >
            
          </Route>
          <Route path={`/need-custom-wordpress-website/`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/basic-information-graphics-logo-design-services/`} element={<Navigate to="/graphic-portfolio/logo-design"></Navigate>}>
            
          </Route>
          <Route path={`/tag/drupal-web-development-indore/`} element={<Navigate to="/ecommerce-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/portfolio-posts/brand-identity-design/`} element={<Navigate to="/graphic-portfolio/brand-identity-design"></Navigate>}>
            
          </Route>
          <Route path={`/tag/website-development-services/`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/basics-information-wordpress-development-services/`} element={ <Navigate to="/ecommerce-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/know-digital-marketing-services/`} element={ <Navigate to="/digital-marketing-services"></Navigate>}>
           
          </Route>
          <Route path={`/best-seo-company-indore-improve-business-enterprise/`} element={ <Navigate to="/digital-marketing-services"></Navigate>}>
           
          </Route>
          <Route
            path={`/for-effective-online-business-is-seo-play-an-important-role/`}
			element={ <Navigate to="/digital-marketing-services"></Navigate>}
          >
           
          </Route>
          <Route path={`/tag/web-development-company/`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/cms-development-services/`} element={<Navigate to="/ecommerce-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/magento-web-development/`} element={ <Navigate to="/ecommerce-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/tag/logo-design-2/`} element={<Navigate to="/graphic-design-services"></Navigate>}>
            
          </Route>
          <Route path={`/portfolio-type/website_design_development/`} element={<Navigate to="/website-portfolio"></Navigate>}>
            
          </Route>
          <Route path={`/tag/wordpress-development/`} element={ <Navigate to="/ecommerce-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/portfolio-type/social-media-design-cat/`} element={<Navigate to="/graphic-portfolio/social-media-design"></Navigate>}>
            
          </Route>
          <Route path={`/portfolio-type/logo-design-cat/`} element={ <Navigate to="/graphic-portfolio/logo-design"></Navigate>}>
           
          </Route>
          <Route path={`/tag/magento/`} element={ <Navigate to="/ecommerce-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/tag/magento-web-development-services/`} element={<Navigate to="/ecommerce-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/shopify-development-services/`} element={<Navigate to="/ecommerce-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/magento-web-development-services/`} element={<Navigate to="/ecommerce-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/business/`} element={ <Navigate to="/graphic-portfolio/business-card-design"></Navigate>}>
           
          </Route>
          <Route path={`/tag/graphic-designing-services/`} element={<Navigate to="/graphic-design-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/logo-desiging/`} element={<Navigate to="/graphic-design-services"></Navigate>}>
            
          </Route>
          <Route path={`/designing`} element={<Navigate to="/graphic-design-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/web-design/`} element={ <Navigate to="/website-design-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/tag/web-designing-company-indore/`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/graphic-designing/`} element={<Navigate to="/graphic-design-services"></Navigate>}>
            
          </Route>
          <Route path={`/webdesigndevelopment`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/shopify-development/`} element={<Navigate to="/ecommerce-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/website-development-company`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/importance-technology-daily-life`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/software-development-company-in-indore/`} element={<Navigate to="/"></Navigate>}>
            
          </Route>
          <Route path={`/Business-card`} element={ <Navigate to="/graphic-portfolio/business-card-design"></Navigate>}>
           
          </Route>
          <Route path={`/category/seo/`} element={ <Navigate to="/digital-marketing-services"></Navigate>}>
           
          </Route>
          <Route path={`/category/uncategorized`} element={<Navigate to="/"></Navigate>}>
            
          </Route>
          <Route path={`/celebrating-first-anniversary-cits/`} element={<Navigate to="/life-at-conative"></Navigate>}>
            
          </Route>
          <Route path={`/creativedesign`} element={<Navigate to="/graphic-design-services "></Navigate>}>
            
          </Route>
          <Route path={`/custom-website-design-development-company-indore/`} element={ <Navigate to="/graphic-design-services"></Navigate>}>
           
          </Route>
          <Route path={`/digitalsolutions`} element={<Navigate to="/digital-marketing-services"></Navigate>}>
            
          </Route>
          <Route path={`/know-joomla-ecommerce-development`} element={ <Navigate to="/ecommerce-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/logodesign`} element={ <Navigate to="/graphic-portfolio/logo-design"></Navigate>}>
           
          </Route>
          <Route path={`/mobiledevelopment`} element={ <Navigate to="/mobile-app-development-services"></Navigate>}>
           
          </Route>
          <Route
            path={`/php-development-services-indore-favorable-every-online-business`}
			element={<Navigate to="/website-design-development-services"></Navigate>}
          >
            
          </Route>
          <Route path={`/portfolio-type/brochure-design-cat/`} element={<Navigate to="/graphic-portfolio/brochure-design"></Navigate>}>
            
          </Route>
          <Route
            path={`/software-company-indore-growing-across-world/LET'S%20TALK`}
			element={<Navigate to="/contact-us"></Navigate>}
          >
            
          </Route>
          <Route
            path={`/software-company-indore-growing-across-world/LET'S%20TALK`}
			element={<Navigate to="/ecommerce-development-services"></Navigate>}
          >
            
          </Route>
          <Route path={`/tag/graphic-design-services-indore/`} element={ <Navigate to="/graphic-design-services"></Navigate>}>
           
          </Route>
          <Route path={`/website-development-enterprise/`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/services`} element={ <Navigate to="/graphic-design-services"></Navigate>}>
           
          </Route>
          <Route path={`/tag/seo/feed/`} element={ <Navigate to="/digital-marketing-services"></Navigate>}>
           
          </Route>
          <Route path={`/professional-logo-graphic-design-services-indore/`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route
            path={`/php-development-services-indore-favorable-every-online-business`}
			element={<Navigate to="/website-design-development-services"></Navigate>}
          >
            
          </Route>
          <Route path={`/tag/magento-web-development/feed`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/responsive-web-designing-services-indore/`} element={ <Navigate to="/website-design-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/tshirt`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/much-know-drupal-web-development/`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/category/seo/`} element={ <Navigate to="/digital-marketing-services"></Navigate>}>
           
          </Route>
          <Route
            path={`/drupal-content-management-system-advantages-disadvantages/`}
			element={<Navigate to="/digital-marketing-services"></Navigate>}
          >
            
          </Route>
          <Route path={`/tag/seo-services-in-indore/`} element={ <Navigate to="/digital-marketing-services"></Navigate>}>
           
          </Route>
          <Route
            path={`/custom-website-design-development-company-indore/LET'S%20TALK`}
			element={<Navigate to="/website-design-development-services"></Navigate>}
          >
            
          </Route>
          <Route path={`/flyer-pamphlet`} element={<Navigate to="/graphic-portfolio/flyer-pamphlet-design"></Navigate>}>
            
          </Route>
          <Route path={`/service-web-development-html`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/service-digital_solution-html`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route
            path={`/growing-significance-cms-development-company-indore-cits`}
			element={<Navigate to="/ecommerce-development-services"></Navigate>}
          >
            
          </Route>
          <Route path={`/flyer-pamphlet`} element={<Navigate to="/graphic-portfolio/flyer-pamphlet-design"></Navigate>}>
            
          </Route>
          <Route path={`/tag/joomla-development/feed`} element={ <Navigate to="/ecommerce-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/portfolio-type/brand-identity-design-cat/feed`} element={ <Navigate to="/graphic-portfolio/brand-identity-design"></Navigate>}>
           
          </Route>
          <Route path={`/web-design-is-an-ongoing-process`} element={ <Navigate to="/website-design-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/tag/web-designing-company-indore/feed`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route
            path={`/search-engine-optimization-services-indore-beneficial-enhance-business/`}
			element={<Navigate to="/digital-marketing-services"></Navigate>}
          >
            
          </Route>
          <Route path={`/social-media`} element={<Navigate to="/digital-marketing-services"></Navigate>}>
            
          </Route>
          <Route path={`/mobiledevelopment`} element={<Navigate to="/mobile-app-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/mobileappdevelopment`} element={<Navigate to="/mobile-app-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/portfolio-type/brochure-design-cat`} element={<Navigate to="/graphic-design-services"></Navigate>}>
            
          </Route>
          <Route path={`/services/graphic-design`} element={<Navigate to="/graphic-design-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/bbm/`} element={<Navigate to="/portflio"></Navigate>}>
            
          </Route>
          <Route path={`/custom-website-design-development-company-indore/`} element={<Navigate to="/graphic-design-services"></Navigate>}>
            
          </Route>
          <Route path={`/webdesign`} element={ <Navigate to="/graphic-design-services"></Navigate>}>
           
          </Route>
          <Route path={`/get-a-quote/conativeitsolutions`} element={ <Navigate to="/contact-us"></Navigate>}>
           
          </Route>
          <Route path={`/tag/graphic-design-services-in-indore/`} element={<Navigate to="/graphic-design-services"></Navigate>}>
            
          </Route>
          <Route
            path={`/after-android-bbm-coming-to-windows-phones-later-this-year/`}
			element={ <Navigate to="/website-design-development-services"></Navigate>}
          >
           
          </Route>
          <Route
            path={`/boost-your-website-by-hiring-a-best-software-development-company/`}
			element={ <Navigate to="/website-design-development-services"></Navigate>}
          >
           
          </Route>
          <Route path={`/conative-it-solutions-inauguration`} element={<Navigate to="/"></Navigate>}>
            
          </Route>
          <Route
            path={`/website-development-design-services-business-need-one`}
			element={ <Navigate to="/website-design-development-services"></Navigate>}
          >
           
          </Route>
          <Route path={`/service-mobile-apps-development`} element={ <Navigate to="/mobile-app-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/Brochure-design`} element={ <Navigate to="/graphic-portfolio/brochure-design"></Navigate>}>
           
          </Route>
          <Route path={`/tag/software-comapny-indore/feed/`} element={<Navigate to="/digital-marketing-services"></Navigate>}>
            
          </Route>
          <Route path={`/get-a-quote/www.conativeitsolutions.com`} element={ <Navigate to="/contact-us"></Navigate>}>
           
          </Route>
          <Route
            path={`/basic-information-custom-software-developamt-company-indore`}
			element={ <Navigate to="/website-design-development-services"></Navigate>}
          >
           
          </Route>
          <Route path={`/tag/website-development-company-indore/feed/`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/website-development-and-design-company/feed/`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/importance-custom-website-designs-development`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/responsive-web-designing-services-indore/feed/`} element={ <Navigate to="/website-design-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/tag/logo-design-2/feed/`} element={<Navigate to="/graphic-portfolio/logo-design"></Navigate>}>
            
          </Route>
          <Route path={`/category/business/feed/`} element={<Navigate to="/graphic-portfolio/business-card-design"></Navigate>}>
            
          </Route>
          <Route path={`/tag/logo-design-company-indore/feed/`} element={<Navigate to="/graphic-portfolio/logo-design"></Navigate>}>
            
          </Route>
          <Route path={`/home/web_development/`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/graphic-design-company/feed`} element={<Navigate to="/graphic-portfolio/logo-design"></Navigate>}>
            
          </Route>
          <Route path={`/portfolio-type/logo-design-cat/?cat_id=22`} element={ <Navigate to="/graphic-portfolio/logo-design"></Navigate>}>
           
          </Route>
          <Route path={`/tag/responsive-website/feed/`} element={ <Navigate to="/graphic-portfolio/logo-design"></Navigate>}>
           
          </Route>
          <Route path={`/portfolio-type/logo-design-cat/?cat_id=`} element={<Navigate to="/graphic-portfolio/logo-design"></Navigate>}>
            
          </Route>
          <Route path={`/portfolio-type/brochure-design-cat/feed/`} element={<Navigate to="/graphic-portfolio/brochure-design"></Navigate>}>
            
          </Route>
          <Route path={`/portfolio-type/business-card-design-cat/feed/`} element={ <Navigate to="/graphic-portfolio/business-card-design"></Navigate>}>
           
          </Route>
          <Route path={`/tips-best-seo-services-online-business`} element={<Navigate to="/digital-marketing-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/web-design/feed/`} element={ <Navigate to="/website-design-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/tag/graphic-designing-services/feed/`} element={ <Navigate to="/ecommerce-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/tag/graphic-design-services/feed/`} element={<Navigate to="/graphic-design-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/graphic-design-services`} element={<Navigate to="/graphic-design-services"></Navigate>}>
            
          </Route>
          <Route path={`/tag/software-development-company-in-indore/feed/`} element={ <Navigate to="/website-design-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/category/cms-development/page/2/`} element={<Navigate to="/ecommerce-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/category/joomla/feed/`} element={<Navigate to="/ecommerce-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/category/cms-development/page/2/`} element={<Navigate to="/ecommerce-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/category/web-development-2`} element={ <Navigate to="/website-design-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/benefit-logo-business-enterprise/`} element={ <Navigate to="/graphic-portfolio/logo-design"></Navigate>}>
           
          </Route>
          <Route path={`/tag/graphic-design-company/`} element={ <Navigate to="/graphic-design-services"></Navigate>}>
           
          </Route>
          <Route path={`/portfolio-type/t-shirt-design-cat/`} element={ <Navigate to="/website-portfolio"></Navigate>}>
           
          </Route>
          <Route path={`/tag/software-development-in-indore`} element={<Navigate to="/"></Navigate>}>
            
          </Route>
          <Route path={`/tag/seo-company-in-indore/`} element={<Navigate to="/"></Navigate>}>
            
          </Route>
          <Route path={`/tag/graphic-design-services-in-indore/feed/`} element={ <Navigate to="/"></Navigate>}>
           
          </Route>
          <Route
            path={`/growing-consequence-responsive-web-designing-services-indore`}
			element={ <Navigate to="/"></Navigate>}
          >
           
          </Route>
          <Route path={`/category/technology`} element={<Navigate to="/"></Navigate>}>
            
          </Route>
          <Route path={`/tag/wordpress-website-development`} element={ <Navigate to="/ecommerce-development-services"></Navigate>}>
           
          </Route>
          <Route path={`/author/admin`} element={<Navigate to="/website-portfolio"></Navigate>}>
            
          </Route>
          <Route path={`/author/admin/page/2/`} element={ <Navigate to="/portfoliowebsite-design-portfolio"></Navigate>}>
           
          </Route>
          <Route path={`/category/logo-2/`} element={ <Navigate to="/website-portfolio"></Navigate>}>
           
          </Route>
          <Route path={`/tag/cms-development-company/`} element={<Navigate to="/ecommerce-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/blog/page/9/`} element={ <Navigate to="/"></Navigate>}>
           
          </Route>
          <Route path={`/tag/inauguration/`} element={ <Navigate to="/"></Navigate>}>
           
          </Route>
          <Route path={`/tag/windows/`} element={ <Navigate to="/"></Navigate>}>
           
          </Route>
          <Route path={`/tag/it-company-in-indore`} element={ <Navigate to="/"></Navigate>}>
           
          </Route>
          <Route path={`/author/admin/page/9/`} element={ <Navigate to="/"></Navigate>}>
           
          </Route>
          <Route path={`/category/news/`} element={ <Navigate to="/"></Navigate>}>
           
          </Route>
          <Route path={`/tag/php-development-services-in-indore/`} element={ <Navigate to="/"></Navigate>}>
           
          </Route>
          <Route path={`/author/admin/page/5/`} element={ <Navigate to="/"></Navigate>}>
           
          </Route>
          <Route path={`/software-development-company/`} element={ <Navigate to="/"></Navigate>}>
           
          </Route>
          <Route path={`/importance-technology-daily-life/`} element={ <Navigate to="/"></Navigate>}>
           
          </Route>
          <Route path={`/portfolio-posts/logo-design/`} element={ <Navigate to="/"></Navigate>}>
           
          </Route>
          <Route path={`/designing`} element={ <Navigate to="/"></Navigate>}>
           
          </Route>
          <Route path={`/lifeatconative`} element={<Navigate to="/life-at-conative"></Navigate>}>
            
          </Route>
          <Route path={`/digital-marketing-and-content-writing`} element={<Navigate to="/digital-marketing-services"></Navigate>}>
            
          </Route>
          <Route path={`/ecommercedevelopment`} element={<Navigate to="/ecommerce-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/web-designer`} element={<Navigate to="/website-design-development-services"></Navigate>}>
            
          </Route>
          <Route path={`/animation`} element={<Navigate to="/animation-services"></Navigate>}>
            
          </Route>
          <Route path="*" element={ <Navigate  to="/"></Navigate>}>
           
          </Route>
        </Routes>
    </>
  );
}

export default AppRouter;
