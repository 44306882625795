import React, { useEffect, useState } from "react";
import Analytics_Counter from "../common/Analytics_Counter";
import Common_Banner from "../common/CommonBanner";
import backendurl from "../constants";
import Meta from "../Meta/Meta";
import { Link } from "react-router-dom";

const Lifeatconative = () => {
  const [data, setData] = useState([]);
  const [lifeSlide, setlifeSlideData] = useState([]);

  useEffect(() => {
    fetch(backendurl + "life-content-show")
      .then((response) => response.json())
      .then((data) => setData(data));

    fetch(backendurl + "lifeitem-show")
      .then((res) => res.json())
      .then((lifeSlide) => setlifeSlideData(lifeSlide));
  }, []);

  return (
    <>
      <Meta
        title={data.meta_title}
        desc={data.meta_desc}
        keywords={data.meta_keywords}
      />
      <Common_Banner title="Life @ Conative" />
      {data.heading || lifeSlide ? (
        <section className="con-life-wr">
          <div className="center-wrapper">
            <div className="con-life-block">
              <div className="con-head-blk">
                <h2>{data.heading}</h2>
                {data.description ? (
                  <span
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  ></span>
                ) : (
                  ""
                )}
              </div>
              {lifeSlide ? (
                <div className="con-lt-rt-wr">
                  {lifeSlide?.map((lifeSlideItem) => (
                    <div className="con-alternate-blk">
						<div class="common-heading con-life-heading-blk"><h3>{lifeSlideItem.name}</h3></div>
						<div class="con-alternate-inner clearfix">
                      <div className="con-left-blk wow pulse ">
                        <figure className="con-life-img-blk">
                          <img
                            src={"/assets/imgupload/" + lifeSlideItem.image}
                            alt="Conative Life"
                          />
                        </figure>
                      </div>
                      <div className="con-right-blk wow fadeIn">
                        {/* <div className="common-heading con-life-heading-blk">
                          <h3>{lifeSlideItem.name}</h3>
                        </div> */}
                        <div
                          className="con-life-description-blk"
                          dangerouslySetInnerHTML={{
                            __html: lifeSlideItem.description,
                          }}
                        ></div>
                        <div className="con-life-btn-blk contact-social-icon-list">
                          <ul>
                            <li>
                              <Link
                                to="https://www.facebook.com/conativeitsolutions"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa-brands fa-facebook-f"></i>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="https://www.linkedin.com/company/conative-it-solutions-pvt-ltd/?trk=biz-companies-cym"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa-brands fa-linkedin-in"></i>
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="https://www.instagram.com/conative_it_solutions/"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <i className="fa-brands fa-instagram"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
					  </div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default Lifeatconative;
