import React, { useEffect, useState } from "react";
import backendurl from "../constants";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import DigitalTV from "../../assets/images/Digital_TV.png";
import { Link } from "react-router-dom";
function DigitalLegacy() {
  const [data, setData] = useState({});
  const [img, setImg] = useState([]);
  const [slide, setSlide] = useState([]);
	
  // fetch data from backend once after initial render 
  useEffect(() => {
    fetch(backendurl + "digital-show")
      .then((response) => response.json())
      .then((data) => { setData(data); setImg(data?.image)});

    fetch(backendurl + "legacySlideItem-show")
      .then((res) => res.json())
      .then((slide) => setSlide(slide));

    // fetch(backendurl + "digital-show")
    //   .then((res) => res.json())
    //   .then((img) => setImg(img.image));
  }, []);

  var experiencedata = "";
  var experienceArr = data?.experience;
  if (experienceArr !== undefined) {
    experiencedata = experienceArr.split(",");
    // 
  }

  return (
    <>
   
        <section className="digital-section-wr" scroll-color="white">
          <div className="center-wrapper">
            <div className="digital-content-area">
              <div className="features-widget clearfix">
                <div className="certification-heading left">
                  <div className="common-heading">
                    <h3>
                      <span>{data?.name || "We are a"}</span>
                      <span>{data?.title || "Tech Company"}</span>
                    </h3>
                  </div>
                </div>
                {data?.description ? (
                  <div className="features-description-blk right ">
                    <span
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    ></span>
                  </div>
                ) : (
                  <div className="features-description-blk right ">
                  <span>
                  <p>Conative IT Solutions has worked with various businesses and organizations to provide instant 
                    <Link to="/">
                      <span style={{color:"#f84525"}}>services</span></Link> and web solutions that enable clients&nbsp;
                      to quickly reconfigure and develop new business potential.</p>
                      </span>
                      </div>
                )}
              </div>
              <div className="experience-list">
                <div className="scroll-line experience-line"></div>
                { experiencedata ?
                  (
                    <ul>
                      {experiencedata?.map((item,idx)=>(
                          <li key={idx} className="wow fadeIn">{item}</li>
                        ))
                      }
                    </ul>
                  ) : (
                    <ul><li className="wow fadeIn" style={{visibility: "visible", animationName: "fadeIn"}}>Learning Ambience</li><li className="wow fadeIn" style={{visibility: "visible", animationName: "fadeIn"}}> Building Relationships</li><li className="wow fadeIn" style={{visibility: "visible", animationName: "fadeIn"}}> Client Satisfaction</li><li className="wow fadeIn" style={{visibility: "visible", animationName: "fadeIn"}}> Brand Exposure</li></ul>
                  )
                }
              </div>
              <div className="digital-image-blk">
                <div className="digital-bg-img wow fadeIn">
                  <img src={DigitalTV} alt="Conative About" />
                </div>
                <div className="digital-slider-area">
                  <Swiper
                    modules={[Navigation, Autoplay]}
                    slidesPerView={1}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    onSlideChange={() => {}}
                    className="digital-slider"
                  >
                    <div className="digital-slider">
                      {slide?.map((post, index) => (
                        <div className="digital-slider-cls" key={post?._id}>
                          <SwiperSlide>
                            <img
                              src={"./assets/imgupload/" + post.image}
                              alt="About Slider"
                            />
                          </SwiperSlide>
                        </div>
                      ))} ||  <div className="digital-slider-cls" >
                      <SwiperSlide>
                      <img src="./assets/imgupload/2023-9_Image_10.jpg" alt="About Slider" />
                      </SwiperSlide>
                      <SwiperSlide>
                      <img src="./assets/imgupload/2023-9_Image_9.jpg" alt="About Slider" />
                      </SwiperSlide>
                      <SwiperSlide>
                      <img src="./assets/imgupload/2023-9_Image_8.jpg" alt="About Slider" />
                      </SwiperSlide>
                      <SwiperSlide>
                      <img src="./assets/imgupload/2023-9_Images_7.jpg" alt="About Slider" />
                      </SwiperSlide>
                      <SwiperSlide>
                      <img src="./assets/imgupload/2023-9_Images_6.jpg" alt="About Slider" />
                      </SwiperSlide>
                      <SwiperSlide>
                      <img src="./assets/imgupload/2023-9_Images_5.jpg" alt="About Slider" />
                      </SwiperSlide>
                      <SwiperSlide>
                      <img src="./assets/imgupload/2023-9_Images_4.jpg" alt="About Slider" />
                      </SwiperSlide>
                      <SwiperSlide>
                      <img src="./assets/imgupload/2023-9_Images_3.jpg" alt="About Slider" />
                      </SwiperSlide>
                      <SwiperSlide>
                      <img src="./assets/imgupload/2023-9_Images_2.jpg" alt="About Slider" />
                      </SwiperSlide>
                      <SwiperSlide>
                      <img src="./assets/imgupload/2023-9_Images_1.jpg" alt="About Slider" />
                      </SwiperSlide>
                    </div>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
   
    </>
  );
}

export default DigitalLegacy;
