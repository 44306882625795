import React, { useState, useEffect } from "react";

function AnivStrip() {
  const [showOnScroll, setShowOnScroll] = useState(false);
  useEffect(() => {
    const handleFtrScroll = () => {
      const footer = document.querySelector('.footer-wr');

      if (footer) {
        const footerPosition = footer.getBoundingClientRect();

        if (footerPosition.top < window.innerHeight) {
          setShowOnScroll(true);

        } else {
          if (window.scrollY > 50) {
            setShowOnScroll(true);
          } else {
            setShowOnScroll(true);
          }
        }
      }
    };

    window.addEventListener('scroll', handleFtrScroll);

    return () => {
      window.removeEventListener('scroll', handleFtrScroll);
    };
  }, []);

  return (
    <div style={{ position: "fixed", zIndex: "1000", opacity: showOnScroll ? 1 : 1, transition: "opacity 0.5s ease-in-out" }}>
      <div className="marquee-wr">
        <div className="marquee-container">
          <figure className="clinking-glass-img"><img src="/assets/images/clinkingGlass.svg" alt="conative 10th aniversary props image" /> </figure>
          <h3> Celebrating <span > Decade of Excellence!</span>CITS marks <span className="bold"> 11 Years </span> – Crafting Solutions, Illuminating Journeys.<a href="https://conativeitsolutions.com/about-us?dragSection=true" className="btn"><span className="aniv-btn-inner">Glimpse of our journey</span></a> </h3>
          <figure className="clinking-glass-img"><img src="/assets/images/clinkingGlass.svg" alt="conative 10th aniversary props image" /> </figure>
        </div>
      </div>
    </div>
  )
}

export default AnivStrip