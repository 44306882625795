import "../../assets/css/Loading.css";
// import "../../../public/assets/js/hurray-confetti.min.js";
// import "../../../public/assets/js/custom";
import LoaderVideo from "../../assets/gif/Conative_loader_latest.gif";



import $ from 'jquery'
import { useEffect } from "react";


function Loadingpage() {
  // Generate a timestamp to make the GIF URL unique

   useEffect(() => {

    $("body").css("overflow", "hidden");
      setTimeout(function () {
        $("#loadingdiv").fadeOut();
        $("body").css("overflowY", "visible");
        $("#root").fadeIn();
      }, 2780);
  }, []);

  function handleLoad() {
    setTimeout(function () {
      $("#loadingdiv").fadeOut();
      $("body").css("overflowY", "visible");
      $("#root").fadeIn();
    }, 2700);
  }

  const timestampQueryParam = new Date().getTime();

  // Append the timestamp to the imported local GIF file path
  const gifPath = `${LoaderVideo}?${timestampQueryParam}`;
  return (
    <>
      <div style={{zIndex:"1000",top:"50%"}} className="loader-video-block">
        <img src={gifPath} onLoad={handleLoad} alt="Conative It Solutions" />
      </div>
    </>
  );
}

export default Loadingpage;